<template>
    <div class="opePreview">
        <div class="mb-6">
            <div class="d-flex justify-space-between align-center mb-4" style="width:100%;">
                <div class="d-flex align-center mb-1">
                    <v-badge class="mt-1 ml-2 mr-6" :color="previewObj.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                    <h2 class="mb-0" style="font-size:24px; line-height:24px; min-height:unset;">{{ previewObj.name }}</h2>
                </div>
                <v-chip color="purple" class="white--text">{{ $t(previewObj.type) }}</v-chip>
            </div>
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-card-text>
                            <!-- <v-img v-if="previewObj.image && previewObj.image.hasOwnProperty('url')" :src="previewObj.image.url"></v-img> -->
                            <v-img class="thumbImage flex-shrink-0 flex-grow-0" 
                                        @error="previewObj.image = default_image_opetype" shaped 
                                        :src="previewObj.image && previewObj.image.url != '' ? previewObj.image.url : default_image_opetype" :lazy-src="default_image_opetype"></v-img>
                            <p class="mb-3 mt-2" v-html="previewObj.description"></p>
                            <span style="position:absolute;right:15px; bottom:15px;">{{ $t('owner') }}: <b>{{previewObj.social_raison}}</b></span>
                            <ul class="pl-0">
                                <li class="d-flex align-center">
                                    <v-icon v-if="previewObj.config.slot_duration" class="mr-2" color="success" small>$check_s</v-icon>
                                    <v-icon v-else class="mr-2" color="error" small>$delete</v-icon>
                                    {{ $t('rdv') }}
                                    <span v-if="previewObj.config.slot_duration" class="ml-1">({{ previewObj.config.slot_duration }} min)</span>
                                </li>
                                <li class="d-flex align-center">
                                    <v-icon v-if="previewObj.config.with_passage" class="mr-2" color="success" small>$check_s</v-icon>
                                    <v-icon v-else class="mr-2" color="error" small>$delete</v-icon>
                                    {{ $t('with_passage') }}
                                </li>
                                <li class="d-flex align-center">
                                    <v-icon v-if="previewObj.type == 'EVENT'" class="mr-2" color="success" small>$check_s</v-icon>
                                    <v-icon v-else class="mr-2" color="error" small>$delete</v-icon>
                                    {{ $t('with_inscription') }}
                                </li>
                                <li class="d-flex align-center">
                                    <v-icon v-if="previewObj.useable_by_childs" class="mr-2" color="success" small>$check_s</v-icon>
                                    <v-icon v-else class="mr-2" color="error" small>$delete</v-icon>
                                    {{ $t('useable_by_childs') }}
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                    <h5 class="mt-6 mb-2">{{$t('entrypoints')}}</h5>
                    <div v-if="previewObj.entrypoints && previewObj.entrypoints.length > 0">
                        <v-card class="mb-2" v-for="(ep, index) in previewObj.entrypoints" :key="'entrypoint_'+index">
                            <v-card-text class="d-flex align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon color="primary" v-if="ep.type =='entryApi'" class="entryIcon mr-4">
                                        $bracketscurly_d
                                    </v-icon>
                                    <v-icon color="primary" v-if="ep.type =='entryLanding'" class="entryIcon mr-4">
                                        $browser_d
                                    </v-icon>
                                    <v-icon color="primary" v-if="ep.type =='entryCallIn'" class="entryIcon mr-4">
                                        $phone_d
                                    </v-icon>
                                    <div>
                                        <h6>
                                            {{ $t(ep.type) }}
                                        </h6>
                                        <p class="mb-0" v-if="ep.config && ep.config.who_manage_new_leads">{{ $t('who_manage_new_leads') }} : <b>{{ $t(ep.config.who_manage_new_leads) }}</b></p>
                                        <p class="mb-0" v-if="ep.config && ep.config.online_appointment_booking">
                                            {{ $t('online_appointment_booking') }} : 
                                            <b>{{ $t(ep.config.online_appointment_booking) }}<span v-if="ep.config.auto_appointment_confirm">{{$t('confirmed')}}</span></b>
                                        </p>
                                        <!-- <p class="mb-0" v-if="ep.config && ep.config.district">{{ $t('district') }} : <b>{{ ep.config.district }}</b></p> -->
                                        <p class="mb-0" v-if="ep.config && ep.config.redirect_number">{{ $t('redirect_number') }} : <b>{{ $t(ep.config.redirect_number) }}</b></p>
                                    </div>
                                </div>
                            <div class="text-right">
                                    <v-img class="thumbImage clickImage flex-shrink-0 flex-grow-0" 
                                        v-if="ep.config && ep.config.landingpage"
                                        @click="showPreview('LANDINGPAGE', ep.config.landingpage)" height="70" width="100"  
                                        @error="ep.config.landingpage.image = default_image_wf" shaped 
                                        :src="ep.config && ep.config.landingpage && ep.config.landingpage.image && ep.config.landingpage.image.hasOwnProperty('url') && ep.config.landingpage.image.url != '' ? ep.config.landingpage.image.url : default_image_lp" :lazy-src="default_image_lp"></v-img>
                                    <a style="text-decoration:underline;" @click="showPreview('TEL', ep.config.template)" v-if="ep.config && ep.config.template">{{$t('seeScript')}}</a>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-alert class="mb-0 mb-2" border="left" color="info" text>
                            {{ $t("noEntryPointsalert") }}
                        </v-alert>
                    </div>
                </v-col>
                <v-col cols="6">
                    <h5 class="mb-2">{{$t('retroplanning')}}</h5>
                    <v-timeline dense v-if="previewObj.retroplanning.length > 0">
                        <v-timeline-item
                                v-for="(item) in previewObj.retroplanning"
                                :key="item.id"
                                color="lightgrey"
                                
                                >
                                <template v-slot:icon>
                                    <v-icon small color="primary">{{ (item.settings && item.settings.icon) ? item.settings.icon : "" }}</v-icon>
                                </template>
                                <v-card
                                    class="cardRP withRP" v-if="item.config && item.config.name">
                                    <v-card-text class="d-flex justify-space-between">
                                        <div style="width:100%">
                                            <div class="d-flex justify-space-between" style="width:100%">
                                                <h5 v-if="item.config.name">{{item.config.name}}</h5>
                                                <h5 v-else> Pas de nom</h5>
                                                <v-chip v-if="item.config && item.config.reference" small color="pink" dark>{{ $t('referenceStep') }}</v-chip>
                                            </div>
                                            <div class="configretro black--text mt-3">
                                                <p v-if="item.config.compute_date_start" class="mb-1">
                                                    {{ $t('start') }} :
                                                    {{item.config.compute_date_start.days}} {{$t("days"+item.config.compute_date_start.placement+"Operation"+item.config.compute_date_start.reference+"at")}} {{item.config.compute_date_start.time}}
                                                </p>
                                                <p v-if="item.config.compute_date_end" class="mb-1">
                                                    {{ $t('end') }} :
                                                    {{item.config.compute_date_end.days}} {{$t("days"+item.config.compute_date_end.placement+"Operation"+item.config.compute_date_end.reference+"at")}} {{item.config.compute_date_end.time}}
                                                </p>
                                            </div>
                                        </div>
                                        <v-img class="thumbImage clickImage flex-shrink-0 flex-grow-0" 
                                    v-if="item.config && item.config.template"
                                    @click="showPreview(item.config.canal, item.config.template)" height="70" width="100"  
                                    @error="item.config.template.image = default_image_template(item.config.canal)" shaped 
                                    :src="item.config && item.config.template && item.config.template.image && item.config.template.image.hasOwnProperty('url') && item.config.template.image.url != '' ? item.config.template.image.url : default_image_template(item.config.canal)" :lazy-src="default_image_template(item.config.canal)"></v-img>
                                    </v-card-text>
                                </v-card>
                                <v-card class="cardRP" color="primary" v-else>
                                    <v-card-title>
                                        <div>
                                            <h5 class="white--text mb-0 pb-0">{{ $t(item.name) }}</h5>
                                        </div>
                                    </v-card-title>
                                </v-card>
                        </v-timeline-item>
                    </v-timeline>
                    <div v-else>
                        <v-alert class="mb-0 mb-2" border="left" color="info" text>
                            {{ $t("noRetroplanningAlert") }}
                        </v-alert>
                    </div>
                    <h5 class="mt-6 mb-2">{{$t('workflows')}}</h5>
                    <div class="mt-2" v-if="previewObj.workflows && previewObj.workflows.length > 0">
                        <v-card v-for="(wf, index) in previewObj.workflows" class="mb-2" :key="'workflow_'+index">
                            <v-card-text class="d-flex align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon color="primary" class="entryIcon mr-4 flex-shrink-0 flex-grow-0">
                                        $networkwired_d
                                    </v-icon>
                                    <div>
                                        <h6>
                                            {{ wf.name }}
                                        </h6>
                                        <p class="mb-0" v-if="wf.description">{{ $t('description') }} : <b v-html="wf.description"></b></p>
                                    </div>
                                </div>
                                <v-img class="thumbImage clickImage flex-shrink-0 flex-grow-0" @click="showPreview('WORKFLOW', wf)" 
                                height="70" width="100"  @error="wf.image.url = default_image_wf" shaped :src="wf.image && wf.image.hasOwnProperty('url') && wf.image.url != '' ? wf.image.url : default_image_wf" :lazy-src="default_image_wf"></v-img>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-alert class="mb-0 mb-2" border="left" color="info" text>
                            {{ $t("noWorkflowsAlert") }}
                        </v-alert>
                    </div>
                </v-col>
            </v-row>
        </div>
        <LibraryPreviewComponent v-if="previewModel" :libType="libType" :model="previewModel" :openPreviewDialog="openPreviewDialog" @close="openPreviewDialog = false"/>
    </div>
</template>
<script>
export default {
    name:"OpeModelPreview",
    props: ['previewObj'],
    components:{
        LibraryPreviewComponent: () => import("@/components/LibraryPreviewComponent"), 
    },
    data() {
        return {
            default_image_wf : "/img/default_WORKFLOW.jpg",
            default_image_lp : "/img/default_LANDINGPAGE.jpg",
            default_image_opetype: "/img/default_OPERATIONTYPE.jpg",
            libType: null,
            previewModel: {},
            previewModel: "",
            openPreviewDialog:false
        }
    },
    methods: {
        showPreview(libType, model){
            this.previewModel = model,
            this.libType = libType,
            this.openPreviewDialog = true
        },
        default_image_template(canal){
            return "/img/default_"+canal+".jpg";
        }
    },
}
</script>
<style lang="scss">
.opePreview{
    .cardRP {
        &::before, &::after {
            display:none;
        }
        h5 {
            font-size:16px;
            padding-bottom:0;
        }
        &.withRP {
            border-left:6px solid var(--v-pink-base)
        }
    }
    .theme--light.v-timeline::before {
        background: var(--v-primary-base)
    }
    .theme--light.v-timeline .v-timeline-item__dot {
        background-color: var(--v-primary-base)
    }
    .v-timeline-item__dot .v-timeline-item__inner-dot {
        height: 32px;
        margin: 3px;
        width: 32px;
    }
    .clickImage {
        cursor:pointer;
    }
}
    
</style>